<template>
  <div>
    <base-dialog ref="deleteDialog" :title="$t('usage.delete_modal_message')" />
    <view-title :label="$t('usage.DTSM_permit')" />
    <div v-if="!file" class="upload-file">
      <form-field-file-list
        :value="dtsmFile"
        editable
        only-one
        :upload-file="false"
        upload-type="image"
        @input="saveDocument"
      />
    </div>
    <document-table v-else :headers="headers" :items="items" :loading="isLoading">
      <template #actions>
        <div class="d-flex justify-end">
          <icon-button tag="label" exact :width="36" class="ml-2">
            <input type="file" style="display: none;" @input="saveDocument($event.target.files)" />
            <v-icon color="primary">mdi-reload</v-icon>
          </icon-button>
          <icon-button exact :width="36" class="ml-2" @click="deleteDocument">
            <v-icon color="primary">mdi-delete</v-icon>
          </icon-button>
        </div>
      </template>
    </document-table>
  </div>
</template>

<script>
// Services
import usersService from '@/services/users';
import mediaService from '@/services/media';
import notificationService from '@/services/notification';

// Schema
import { dtsmPermitSchema } from '@/schemas/contract.schema';
import FormFieldFileList from '@/components/schema/FormFieldFileList.vue';

import BaseDialog from '@/components/BaseDialog.vue';
import IconButton from '@/components/IconButton.vue';
import DocumentTable from './DocumentTable.vue';

export default {
  name: 'DTSMPermit',

  components: {
    ViewTitle: () => import('@/components/schema/ViewTitle.vue'),
    IconButton,
    DocumentTable,
    BaseDialog,
    FormFieldFileList,
  },

  props: {
    file: {
      type: Object,
      default: null,
    },
    usageId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      dtsmFile: [],
      submitContractLoading: false,
      isLoading: false,
    };
  },

  computed: {
    headers() {
      return [
        { text: this.$t('label.file'), value: 'file', sortable: false },
        { text: '', value: 'actions', sortable: false, align: 'right' },
      ];
    },

    items() {
      return [
        {
          file: this.file,
        },
      ];
    },
  },

  watch: {
    documents: {
      handler() {
        this.initialData.dtcmPermitScan = this.file ? [this.file] : [];
      },
      deep: true,
    },
  },

  methods: {
    saveDocument(files) {
      const bodyRequest = {};
      const file = files[0];
      const fileReader = new FileReader();

      fileReader.readAsDataURL(file);

      fileReader.addEventListener('load', async () => {
        mediaService.uploadImage(file).then(tempFileData => {
          bodyRequest.dtcmPermitScan = tempFileData.id;

          usersService
            .saveDocuments(bodyRequest, this.usageId)
            .then(response => {
              if (!this.file) {
                notificationService.success(this.$t('usage.document_is_created'), 2000);
              } else {
                notificationService.success(this.$t('usage.document_is_updated'), 2000);
              }
              this.$emit('update-documents', response.data);
            })
            .finally(() => {
              this.isLoading = false;
            });
        });
      });
    },

    async deleteDocument() {
      const needDelete = await this.$refs.deleteDialog.open();

      if (!needDelete) return;

      this.isLoading = true;

      const bodyRequest = {
        dtcmPermitScan: null,
      };
      usersService
        .saveDocuments(bodyRequest, this.usageId)
        .then(response => {
          notificationService.success(this.$t('usage.document_is_deleted'), 2000);
          this.$emit('update-documents', response.data);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  dtsmPermitSchema,
};
</script>

<style>
.upload-file {
  max-width: 450px;
}
</style>
