<template>
  <v-data-table
    class="document-table"
    :headers="headers"
    :items="items"
    hide-default-footer
    :loading="loading || tableLoading"
  >
    <template v-for="slot in getItemSlots()" v-slot:[slot]="slotProps">
      <slot :name="slot" v-bind="slotProps" />
    </template>
    <template #item.file="{ item }">
      <a v-if="item.file" @click.stop="downloadFile(item.file)">
        {{ item.file.name }}
      </a>
      <span v-else>—</span>
    </template>
    <template #item.actions>
      <slot name="actions" />
    </template>
  </v-data-table>
</template>

<script>
import mediaService from '@/services/media';
import { getBaseUrlWithoutApi } from '@/http/getBaseURL';
import { download } from '@/utils/common';

export default {
  name: 'DocumentTable',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      tableLoading: false,
    };
  },

  methods: {
    getItemSlots() {
      return Object.keys(this.$scopedSlots);
    },

    async downloadFile(file) {
      this.tableLoading = true;
      const { blob } = await this.getPrivateMediaObject(file.privateUrl);
      download(file.name, blob);
      this.tableLoading = false;
    },

    getPrivateMediaObject(url) {
      return mediaService.getPrivateMediaObject(getBaseUrlWithoutApi() + url);
    },
  },
};
</script>
