<template>
  <div>
    <base-dialog ref="deleteDialog" :title="$t('usage.delete_modal_message')" />
    <view-title :label="$t('usage.contract_with_landlords')" />
    <form-modal
      v-if="isEmptyObject(items[0])"
      :title="$t('usage.add_contract_with_landlords')"
      :schema="$options.contractWithLandlordsSchema"
      :initial-data="initialData"
      :async="true"
      @submit="saveDocument"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn text color="primary" v-bind="attrs" v-on="on">
          <v-icon>mdi-plus</v-icon>
          {{ $t('button.add_document') }}
        </v-btn>
      </template>
      <template #footer="{ valid }">
        <v-btn
          :disabled="!valid"
          :loading="submitContractLoading"
          type="submit"
          elevation="0"
          color="primary"
          class="mt-2"
        >
          {{ $t('button.add') }}
        </v-btn>
      </template>
    </form-modal>
    <document-table v-else :headers="headers" :items="items" :loading="isLoading">
      <template #actions>
        <div class="d-flex justify-end">
          <form-modal
            :title="$t('usage.edit_contract_with_landlords')"
            :schema="$options.contractWithLandlordsSchema"
            :initial-data="initialData"
            :async="true"
            @submit="saveDocument"
          >
            <template v-slot:activator="{ on, attrs }">
              <icon-button exact :width="36" v-bind="attrs" v-on="on">
                <v-icon color="primary">mdi-pencil</v-icon>
              </icon-button>
            </template>
            <template #footer="{ valid }">
              <v-btn
                :disabled="!valid"
                :loading="submitContractLoading"
                type="submit"
                elevation="0"
                color="primary"
                class="mt-2"
              >
                {{ $t('button.edit') }}
              </v-btn>
            </template>
          </form-modal>
          <icon-button exact :width="36" class="ml-2" @click="deleteDocument">
            <v-icon color="primary">mdi-delete</v-icon>
          </icon-button>
        </div>
      </template>
    </document-table>
  </div>
</template>

<script>
import { isEmptyObject } from '@/utils/common';
import { translateDate } from '@/utils/dateFormatting';
import format from 'date-fns/format';

import { createModelData } from '@/schemas/createModelData';

// Services
import usersService from '@/services/users';
import notificationService from '@/services/notification';

// Schema
import { contractWithLandlordsSchema } from '@/schemas/contract.schema';

import BaseDialog from '@/components/BaseDialog.vue';
import IconButton from '@/components/IconButton.vue';
import FormModal from '@/components/schema/FormModal.vue';
import DocumentTable from './DocumentTable.vue';

export default {
  name: 'ContractWithLandlords',

  components: {
    ViewTitle: () => import('@/components/schema/ViewTitle.vue'),
    IconButton,
    DocumentTable,
    FormModal,
    BaseDialog,
  },

  props: {
    documents: {
      type: Object,
      required: true,
    },
    usageId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      initialData: createModelData(this.$options.contractWithLandlordsSchema),
      submitContractLoading: false,
      isLoading: false,
    };
  },

  computed: {
    headers() {
      return [
        { text: this.$t('label.title_of_document'), value: 'title', sortable: false, width: '20%' },
        { text: this.$t('label.file'), value: 'file', sortable: false, width: '17%' },
        { text: this.$t('label.contract_start_date'), value: 'startDate', sortable: false, width: '17%' },
        { text: this.$t('label.contract_end_date'), value: 'endDate', sortable: false, width: '17%' },
        { text: this.$t('label.work_model'), value: 'workModel', sortable: false, width: '16%' },
        { text: '', value: 'actions', sortable: false, align: 'right', width: '0' },
      ];
    },

    items() {
      return [
        {
          title: this.documents.title,
          workModel: this.documents.workModel,
          startDate: this.formatDate(this.documents.startDate),
          endDate: this.formatDate(this.documents.endDate),
          file: this.documents.contractWithClientScan,
        },
      ];
    },
  },

  watch: {
    documents: {
      handler() {
        this.initialData.title = this.documents.title;
        this.initialData.startDate = this.documents.startDate;
        this.initialData.endDate = this.documents.endDate;
        this.initialData.workModel = this.documents.workModel;
        this.initialData.contractWithClientScan = this.documents.contractWithClientScan
          ? [this.documents.contractWithClientScan]
          : [];
      },
      deep: true,
    },
  },

  mounted() {
    this.initialData.title = this.documents.title;
    this.initialData.startDate = this.documents.startDate;
    this.initialData.endDate = this.documents.endDate;
    this.initialData.workModel = this.documents.workModel;
    this.initialData.contractWithClientScan = this.documents.contractWithClientScan
      ? [this.documents.contractWithClientScan]
      : [];
  },

  methods: {
    isEmptyObject,
    formatDate(date) {
      if (date) {
        const noFormatDate = new Date(date);
        return translateDate(format(noFormatDate, 'd L yyyy'));
      }
      return date;
    },
    saveDocument([data, closeModal]) {
      this.isLoading = true;
      const bodyRequest = {
        ...data,
        startDate: data.startDate || null,
        endDate: data.endDate || null,
        contractWithClientScan: data.contractWithClientScan.length
          ? data.contractWithClientScan[0].backgroundFileId || undefined
          : null,
      };

      usersService
        .saveDocuments(bodyRequest, this.usageId)
        .then(response => {
          if (isEmptyObject(this.items[0])) {
            notificationService.success(this.$t('usage.document_is_created'), 2000);
          } else {
            notificationService.success(this.$t('usage.document_is_updated'), 2000);
          }
          this.$emit('update-documents', response.data);
          closeModal();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async deleteDocument() {
      const needDelete = await this.$refs.deleteDialog.open();

      if (!needDelete) return;

      this.isLoading = true;

      const bodyRequest = {
        title: '',
        workModel: '',
        startDate: null,
        endDate: null,
        contractWithClientScan: null,
      };
      usersService
        .saveDocuments(bodyRequest, this.usageId)
        .then(response => {
          notificationService.success(this.$t('usage.document_is_deleted'), 2000);
          this.$emit('update-documents', response.data);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  contractWithLandlordsSchema,
};
</script>
