<template>
  <div>
    <base-dialog ref="deleteDialog" :title="$t('agreement.deletion_confirmation')" />
    <v-row class="mt-5 mt-md-10">
      <v-col v-for="agreement in agreements" :key="agreement.id" cols="6" md="3">
        <v-hover v-slot="{ hover }" style="cursor: pointer">
          <v-card
            :elevation="hover ? 10 : 6"
            class="document-wrapper d-flex flex-column justify-center"
            @click="downloadAgreement(agreement)"
          >
            <v-img :src="agreement.document.previewUrl" class="align-end">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </v-row>
              </template>
              <div
                v-if="hover && !canDownload(agreement)"
                class="document-message d-flex align-center justify-center white--text font-weight-medium text-body-1 text-md-h5 text-center px-3 py-3"
              >
                <span>{{ t$('agreement.not_singed_yet') }}</span>
              </div>
              <v-card-actions class="template-actions justify-end" :class="media.isMobile || hover ? 'show' : 'hide'">
                <icon-button
                  color="white"
                  :width="44"
                  class="px-0 not-before"
                  style="outline: 1px solid #C7D6FF "
                  @click.stop="deleteAgreement(agreement)"
                >
                  <v-icon color="primary" size="24">
                    mdi-delete
                  </v-icon>
                </icon-button>
              </v-card-actions>
            </v-img>
          </v-card>
        </v-hover>
        <p class="text-body-1 text--md-h6 mt-2 mt-md-6 mb-0" style="word-break: break-all;">
          {{ agreement.document.name || '-' }}
        </p>
        <p class="text-caption grey--text" style="word-break: break-all;">{{ agreement.contractorEmail }}</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import contractService from '@/services/contract';
import notificationService from '@/services/notification';

import IconButton from '@/components/IconButton.vue';
import BaseDialog from '@/components/BaseDialog.vue';

export default {
  name: 'AgreementsList',

  inject: ['media'],

  components: { IconButton, BaseDialog },

  props: {
    agreements: { type: Array, required: true },
  },

  methods: {
    async deleteAgreement(agreement) {
      const needDelete = await this.$refs.deleteDialog.open();

      if (!needDelete) return;

      contractService.deleteAgreement(agreement.id).then(() => {
        notificationService.success(this.$t('templates.template_is_deleted'), 2000);
        this.$emit('update-documents');
      });
    },

    canDownload(agreement) {
      return agreement.clientStatus === 'completed' || agreement.contractorStatus === 'completed';
    },

    downloadAgreement(agreement) {
      if (!this.canDownload(agreement)) {
        return;
      }

      contractService.downloadAgreement(agreement.id, `${agreement.document.name.split('.')[0]}.pdf`);
    },
  },
};
</script>

<style lang="scss">
.document-wrapper {
  position: relative;

  .document-message {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: default;
  }
}
</style>
